import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { Box } from '@twilio-paste/box';
import { Text } from '@twilio-paste/text';
import { Button } from '@twilio-paste/button';
import { PlusIcon } from '@twilio-paste/icons/esm/PlusIcon';
import { LoadingIcon } from '@twilio-paste/icons/esm/LoadingIcon';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
import { Grid } from '../../../components/grid';
export const pageQuery = graphql`
  {
    mdx(fields: {slug: {eq: "/icons/how-to-add-an-icon/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageAside = makeShortcode("PageAside");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h1>{props.pageContext.frontmatter.title}</h1>
        <hr></hr>
        <h2>{`Finding an icon`}</h2>
        <ol>
          <li parentName="ol">{`Make sure the icon you need doesn’t already exist in `}<a parentName="li" {...{
              "href": "/icons"
            }}>{`our icon list`}</a>{`.`}</li>
          <li parentName="ol">{`If the icon can be used for a similar use case to yours in multiple products ("UI icon"), find a new icon by searching through the `}<a parentName="li" {...{
              "href": "https://app.streamlineicons.com/streamline-regular"
            }}>{`Streamline app`}</a>{`. If the icon needs to be reserved for use in your product only ("logo icon"), please `}<a parentName="li" {...{
              "href": "https://www.twilio.com/brand/resources/logos"
            }}>{`request one from our Brand team`}</a>{`. `}</li>
          <li parentName="ol">{`Create a `}<a parentName="li" {...{
              "href": "https://github.com/twilio-labs/paste/discussions"
            }}>{`GitHub discussion`}</a>{` with:`}<ol parentName="li">
              <li parentName="ol">{`A link or screenshot of the icon`}</li>
              <li parentName="ol">{`A brief explanation or mockup of what you'll need it for`}</li>
              <li parentName="ol">{`Whether this icon is a logo icon or a UI icon. `}</li>
            </ol></li>
          <li parentName="ol">{`We'll discuss in GitHub if the icon should be added to Paste.`}<ol parentName="li">
              <li parentName="ol">{`If the icon will be added to Paste, create a branch in
the `}<a parentName="li" {...{
                  "href": "https://share.goabstract.com/7cab6888-78ef-44c7-bd8e-63214dcf6f3d"
                }}>{`"Paste components" Abstract project`}</a>{`.`}</li>
              <li parentName="ol">{`If the icon won’t be added to Paste, create a branch in your own Abstract project
and skip to `}<a parentName="li" {...{
                  "href": "/icons/how-to-add-an-icon#using-a-streamline-icon"
                }}>{`Using a Streamline icon`}</a>{`.`}</li>
            </ol></li>
        </ol>
        <h2>{`Setting up an icon in Paste Icons`}</h2>
        <p>{`Written for Sketch 67.2`}</p>
        <ol>
          <li parentName="ol">{`Open "icons.sketch".`}</li>
          <li parentName="ol">{`Go to the page "Symbols".`}</li>
          <li parentName="ol">{`Duplicate the empty template artboard "`}<a parentName="li" {...{
              "href": "https://share.goabstract.com/0ddd7349-bbbb-4a1f-85b1-be88622ef1ec?mode=design"
            }}>{`IconName`}</a>{`".`}</li>
          <li parentName="ol">{`Group the new artboard in the section that fits its use case (e.g. a "filter" icon would belong in the "actions" section). Make sure the new artboard is aligned evenly on the canvas with the existing icon artboards.`}</li>
          <li parentName="ol">{`Turn the new artboard into a symbol.`}</li>
          <li parentName="ol">{`In the artboard name, replace "IconName" with the name of your icon. Follow the naming pattern of the existing icon artboards in that section (e.g. "action/Filter").`}</li>
        </ol>
        <Callout mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">How to name icon artboards in Sketch</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    Use PascalCase. For an interface icon, give the icon a name that describes its purpose rather than its content. For
    example, "LinkExternal" instead of "ArrowUpRight".
  </CalloutText>
  <CalloutText mdxType="CalloutText">
    If you're adding a logo icon or an icon used to brand a product feature, name it "product/[ProductName][SubproductName (optional)]". For
    example: "product/CodeExchangeCommunity".
  </CalloutText>
        </Callout>
        <h2>{`Using a Streamline icon`}</h2>
        <ol>
          <li parentName="ol">{`Copy and paste the Streamline icon onto your icon artboard.`}</li>
          <li parentName="ol">{`Select all the layers and make sure the border width is 1.`}</li>
          <li parentName="ol">{`Select the layer group. Lock the width-height aspect ratio. If the icon appears
larger than the bounds of the artboard, shrink the icon down so that it's visually aligned with our default text size. Use the "icon tester" artboard on the Icon tester page in the Sketch file to help. Resizing the icon to 16–18 px usually works.`}</li>
          <li parentName="ol">{`Duplicate the layer group. Rename the original group to "source" and hide it.`}</li>
          <li parentName="ol">{`Ungroup the new layer group. You’ll be working with these layers.
`}<span parentName="li" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "464px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "91.42156862745098%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsSAAALEgHS3X78AAABjElEQVQ4y4WT2Y6CQBRE+f+fGl9MfDIMEhdQUVzYGfZFeJhjt5klEaiYpqVvdVXXbZSiKKIwLvymShvP91zX/RJo2/YxirIslTzPXMfz1TIwck1X5/P5crlUVbWu624UVVUpRVFWVZ1EWZGVTdt2fd93/ePRNQJ5Xgz9kiRR0jTlgf7hcLAsS1U/wW63Y9Q0Lc3SIcRxrGQC/DmdTkEQ2LbNLufz+XK58CYbBrm8yIjv93vf922B2+0GE36OpXEyFVEUUYqT1WqFW2xvNpv1ej1NlraxirLjOIxhGN7vd8/zeD9GZpntsU1CiNOnxWLBXBXRvaVRz+14BcaDqK7XKxNazwLLlcBbJtaof7aKUuIxDGP2MTsC62iaJuHh6q0s9YRCAfOnbSklG464PAiAz5sh5y/bMhXqdF3HPC7ImVYROD0fCvw3bUkm6r936GffCTLOiQFlRlMAC9vtlv6xNK0sv0QmkUAsMBTbPzIH42LQW5yjxoGZcGD0p88s0+OrQpO7xW1xPVfethHlb+J36PzqaTk3AAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Sample layer groups",
                "title": "Sample layer groups",
                "src": "/static/2bc908f6f12a177dbd8c457e6e612db5/69096/using-streamline-icons-3.png",
                "srcSet": ["/static/2bc908f6f12a177dbd8c457e6e612db5/e7c18/using-streamline-icons-3.png 408w", "/static/2bc908f6f12a177dbd8c457e6e612db5/69096/using-streamline-icons-3.png 464w"],
                "sizes": "(max-width: 464px) 100vw, 464px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
    `}</span></li>
        </ol>
        <h2>{`Formatting an icon`}</h2>
        <ol>
          <li parentName="ol">{`Convert your icons to outlines so that the width of the lines stay consistent.`}<ol parentName="li">
              <li parentName="ol">{`Select all the layers and press "OPTION + COMMAND + O", or use Sketch's menu, "Layer > Convert to Outlines".`}</li>
              <li parentName="ol">{`Make sure each layer now has a fill and not a stroke.`}</li>
            </ol></li>
          <li parentName="ol">{`Create a union with all the pieces of your icon. This will merge individual pieces
together to create one shape. You can find the Union tool in Sketch's toolbar at the
top or go to "Layer > Combine > Union".`}</li>
          <li parentName="ol">{`Apply the layer style "icon/color-text-icon" from one of the Paste themes to the
combined shape. In the layer list, you should only have this shape and the hidden
"source" group.`}</li>
          <li parentName="ol">{`Rename the combined shape to the icon name in lowercase with spaces (e.g., "link external").`}</li>
          <li parentName="ol">{`Make sure the icon is centered on the artboard both vertically and horizontally.
If you’re working with an asymmetric shape, you might need to adjust it manually to make
sure it’s `}<a parentName="li" {...{
              "href": "https://blog.marvelapp.com/optical-adjustment-logic-vs-designers/"
            }}>{`optically centered`}</a>{`.
For example, you might need to move a "play" icon a bit
right-of-center to account for the low visual weight on its right side.`}</li>
          <li parentName="ol">{`Make sure there are no transforms on the shape. If there are, go to "Layer > Combine > Flatten".
`}<span parentName="li" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "162px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "90.12345679012346%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsSAAALEgHS3X78AAABYUlEQVQ4y6WT3W6CQBCFef+H8AEwJj5ATSxce4WBmNR4YQ1KwV0soolI+4WBRbQlJp7EdXZ2zvycUevQ4PtpGIplrP3TuCfjIuXpdDr0QiobvsVFKcVZlmWapj+9KIrier2mFWpykiTn83k+n9u2fTweCSLRHU08y49lEAR5nmutWzINLxaL8XiM0VNZUkCrK/NRWnOhpSzL6KroBQG6im8FU42M1be6OfcPNjfVUZvmswqi55921sCsuibTiSSN4xgJbvdp/PIERK2azABhGH41iKKIEw/RGLvdLqrARmFi8NTZMxF5Bdd1Pc+7XC5UEybLI3o6ffd9n2xct9ttu2chMwxBo9FoOBwSIWWpQ8D6c41zMBjMZjPJ1alMMvmdOI4Dn1wMBlMqbzabt8lktVpREOEI7szMPWkgs0ETkUghThEPT1vZqE2T0qoAW56MU4QEMnDnL5k94D9/u+f4BfwCv+rwh/93/XwAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Number of transforms preview",
                "title": "Number of transforms preview",
                "src": "/static/bef7f8d0c2d5d1b1bd4cbee97483d079/8e581/formatting-an-icon-1.png",
                "srcSet": ["/static/bef7f8d0c2d5d1b1bd4cbee97483d079/8e581/formatting-an-icon-1.png 162w"],
                "sizes": "(max-width: 162px) 100vw, 162px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
    `}</span></li>
          <li parentName="ol">{`Rename the layers to "icon".`}</li>
        </ol>
        <h2>{`Exporting the icon`}</h2>
        <ol>
          <li parentName="ol">{`Make sure you have the `}<a parentName="li" {...{
              "href": "https://www.sketch.com/extensions/plugins/svgo-compressor/"
            }}>{`SVGO compressor Sketch plugin`}</a>{` installed.`}</li>
          <li parentName="ol">{`Export the icon artboard (not the combined shape) as an SVG. The template artboard
is already set up to do this.`}</li>
          <li parentName="ol">{`Make sure the SVG code is clean:`}<ol parentName="li">
              <li parentName="ol">{`You shouldn’t see `}<inlineCode parentName="li">{`id`}</inlineCode>{`. If you do, make sure the SVGO compressor plugin is properly installed.`}</li>
              <li parentName="ol">{`You shouldn’t see `}<inlineCode parentName="li">{`transform`}</inlineCode>{`. If you do, flatten your shape.`}</li>
              <li parentName="ol">{`You should see `}<inlineCode parentName="li">{`width=”20" height="20"`}</inlineCode>{`. If you don’t, check that the artboard you’re
exporting is 20px × 20px and that you’re exporting the artboard, not the layer.`}</li>
            </ol></li>
          <li parentName="ol">{`If you're adding a logo icon, add the word "Product" to the
beginning of the SVG file name. For example: "ProductCodeExchangeCommunity".`}</li>
          <li parentName="ol">{`Commit your changes in Abstract and request a review from the Paste design team.`}</li>
        </ol>
        <h2>{`Adding the icon to the Paste repository`}</h2>
        <p>{`Once the icon is designed and exported, we need to get it in the hands of our developers.
There are two ways for icons to be added into the `}<inlineCode parentName="p">{`@twilio-paste/icons`}</inlineCode>{` code package:`}</p>
        <ol>
          <li parentName="ol">{`You can `}<a parentName="li" {...{
              "href": "https://github.com/twilio-labs/paste/issues"
            }}>{`file an issue on Github`}</a>{` providing all the
information you have about the icon. Ideally, this issue would attach the SVG icon generated in
the above steps. This method is hands-off, but comes at the cost of needing to wait between 2 to 6 weeks
for us to plan and execute on the work.`}</li>
          <li parentName="ol">{`Alternatively, you can open a Pull Request (PR) on our repository with the required changes.
This self-service approach would greatly speed up the process of getting your icon into the Paste package
because it bypasses our team's sprint practices.`}</li>
        </ol>
        <h3>{`Self-service instructions`}</h3>
        <ol>
          <li parentName="ol">{`Fork the Paste repository because PRs can only be open against forks and not branches for security reasons.`}</li>
          <li parentName="ol">{`Clone your newly forked Paste repository: `}<inlineCode parentName="li">{`git clone <url>`}</inlineCode></li>
          <li parentName="ol">{`Navigate inside the newly cloned repository with `}<inlineCode parentName="li">{`cd paste`}</inlineCode></li>
          <li parentName="ol">{`Install the dependencies with `}<inlineCode parentName="li">{`yarn install`}</inlineCode></li>
          <li parentName="ol">{`Add all the new `}<inlineCode parentName="li">{`svg`}</inlineCode>{` icon files into the `}<inlineCode parentName="li">{`packages/paste-icons/svg/`}</inlineCode>{` folder.`}</li>
          <li parentName="ol">{`From the root Paste folder, run the following command in your terminal: `}<inlineCode parentName="li">{`yarn workspace @twilio-paste/icons convert`}</inlineCode></li>
          <li parentName="ol">{`Verify your new icons were added correctly by checking the `}<inlineCode parentName="li">{`packages/paste-icons/src`}</inlineCode>{` folder to see the newly created source files.`}</li>
          <li parentName="ol">{`Commit your changes and submit a new PR on Github!`}</li>
        </ol>
        <h2>{`Troubleshooting`}</h2>
        <p>{`If you run into any issues with creating your icon, double check you've followed
all the steps. In the past, we've seen icons that had small artifacts or
missing pieces because of the order of layers within a combined shape
or union. Try moving layers around to get the shape you need, or flattening the icon shape layer.`}</p>
        <p>{`Get in touch with the Paste team through Slack at #help-design-system.`}</p>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      